import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDataContext } from '../../../DataContext'

const Login = () => {
    const history = useNavigate()
    const { login } = useDataContext()
    const username = useRef(), password = useRef()

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(username.current.value.length < 1) return alert("Musíš vyplnit jméno!")
        if(password.current.value.length < 1) return alert("Musíš vyplnit heslo!")
        let err = await login(username.current.value, password.current.value)
        if(err.err) return alert("Špatné jméno nebo heslo!")
        history('/dashboard')
    }
    return (
        <div className='bgimg'>
            <div className='header'>
                <button className='backHeader' onClick={() => history("/")}>Back</button>
            </div>
            <div className='loginContatiner'>
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
                        <form onSubmit={handleSubmit}>
                            <h2 className='text-center pb-3'>Login</h2>
                            <div className="form-outline mb-2">
                                <input type="text" id="loginName" className="form-control" ref={username} />
                                <label className="form-label" htmlFor="loginName">Přihlašovací jméno</label>
                            </div>

                            <div className="form-outline mb-2">
                                <input type="password" id="loginPassword" className="form-control" ref={password} />
                                <label className="form-label" htmlFor="loginPassword">Heslo</label>
                            </div>
                            <button type='submit' className="btn mb-4 w-100 loginButton">Sign in</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login