import { useEffect, useState } from "react";

import { fetch_util } from "../util/_index";

const useFetch = (endpoint, options = {}, autoFetch = true) => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [err, setErr] = useState(null)

    const fetchData = async () => {
        setIsLoading(true)
        try{
            let res = await fetch(`${process.env.REACT_APP_API_LINK}${endpoint}`, options)
            let data = await res.json()
            setData(data)
        } catch(err){
            setErr(err)
            console.log(err, endpoint, options)
            alert(err)
        } finally {
            setIsLoading(false)
        }
    }
    
    useEffect(() => {
        if(autoFetch) fetchData()
    }, [])

    const reFetch = async () => {
        setIsLoading(true)
        await fetchData()
    }

    return { data, isLoading, err, reFetch, fetchData }
      

}


export default useFetch